<template>
  <v-app 
    :style="bodyHeight ? cssVars : ''"
  >
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import MainLayout from "@/layouts/MainLayout"
import AuthLayout from "@/layouts/AuthLayout"
import isMobileMixin from "./mixins/isMobileMixin"
import onResize from "./mixins/onResize"

export default {
  data() {
    return {
      height: window.innerHeight,
      bodyHeight: true
    }
  },
  mixins: [isMobileMixin, onResize],
  components: {
    MainLayout,
    AuthLayout
  },
  methods: {
    onResize() {
      this.height = window.innerHeight;
    },
  },
  computed: {
    layout() {
      return  (this.$route.meta.layout || 'main') + '-layout'
    },
    cssVars () {
      return {
        'height': this.height + 'px',
      }
    }
  },
  mounted() {
    this.isMobile() ? 
    this.$root.$on('bodyHeight', (val) => {
      this.bodyHeight = val
    }) : ''
  },
}
</script>

<style lang="scss">

//********************** */
//Global styles
//********************** */
* {
  body {
    .v-application {
      font-family: 'Poppins', sans-serif !important;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      button,
      caption,
      input,
      span,
      .text-h1, 
      .text-h2, 
      .text-h3, 
      .text-h4, 
      .text-h5, 
      .text-h6,
      .subtitle-1,
      .subtitle-2,
      .body-1,
      .body-2,
      .overline {
        font-family: 'Poppins', sans-serif !important;
      } 
    }
  }
}

.theme--light.v-application {
  background-image: url('./assets/img/bg.jpg') !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.pageTitle {
  color: #fff;
  font-size: 50px;
}

body::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgb(43, 2, 2);        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #8a0e05;  /* creates padding around scroll thumb */
}

.v-btn  {
  &.mainButton {
    background: linear-gradient(90deg, #F14336 0%, #A6160B 100%);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 25px 70px !important;
    text-transform: capitalize;
  }
}

.pevent-none {
    pointer-events: none;
}

.v-toolbar__items {
  .row {
    display: block;
    margin: 0px;
  }
}

.v-application--wrap {
  min-height: 100% !important;
}

.v-main,
.main-layout {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.v-main {
  flex-grow: 1;
  position: relative;
}

.row,
.container {
  height: 100%;
}

</style>
