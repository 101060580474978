<template>
  <div class="main-nav">
    <v-app-bar app dark color="#A6160B">
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon @click="sidebar = !sidebar"> </v-app-bar-nav-icon>
      </span>
      <v-container class="d-flex">
        <v-toolbar-title>
          <router-link
            to="/"
            tag="span"
            style="cursor: pointer"
            v-slot="{ navigate }"
            custom
          >
            <span @click="navigate" @keypress.enter="navigate" role="link">
              <v-img
                class="mx-2"
                src="../assets/img/logo.svg"
                max-width="130"
                contain
              ></v-img>
            </span>
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-xs-only">
          <router-link to="/about" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title> How it works </v-list-item-title>
            </v-list-item>
          </router-link>
          <app-music></app-music>
          <app-settings></app-settings>
        </v-toolbar-items>
      </v-container>

      <app-music-button></app-music-button>
    </v-app-bar>

    <v-navigation-drawer
      v-model="sidebar"
      absolute
      temporary
      color="#14171f"
      dark
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Menu </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group active-class="text--accent-3">
          <router-link to="/" class="text-decoration-none">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/about" class="text-decoration-none">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-information</v-icon>
              </v-list-item-icon>
              <v-list-item-title>How it works</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-list-item @click="clickSettings">
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import AppSettings from "./AppSettings.vue";
import AppMusic from "./AppMusic.vue";
import AppMusicButton from "./AppMusicButton.vue";

export default {
  components: { AppSettings, AppMusic, AppMusicButton },
  name: "AppTheNav",
  data() {
    return {
      sidebar: false,
      dialog: true,
    };
  },
  methods: {
    clickSettings() {
      (this.sidebar = false), this.$root.$emit("clickSettings", this.dialog);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-toolbar__title {
  display: flex;
  align-items: center;
}
.v-toolbar__items {
  align-items: center;
}

.v-toolbar__items .row {
  height: auto;
}
</style>
