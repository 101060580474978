<template>
   <div
      v-if="musicButton"
      class="music-button text-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="playMusicBtn"
            v-if="playMusic"
            fab
            dark
            large
            v-bind="attrs"
            v-on="on"
            class="playMusic"
          >
          <v-icon dark>mdi-play</v-icon>
          </v-btn>

          <v-btn
            @click="pauseMusicBtn"
            v-else
            fab
            dark
            large
            v-on="on"
            v-bind="attrs"
            class="pauseMusic"
          >
            <v-icon dark> mdi-pause </v-icon>
          </v-btn>
        </template>
        <span>Play/Pause Radio</span>
      </v-tooltip>
    </div>
</template>

<script>
export default {
  data() {
    return {
      playMusic: true,
      musicButton: false,
    }
  },
  methods: {
    playMusicBtn() {
      this.playMusic = false
      this.$root.$emit('clickedPlayMusicBtn')
    },
    pauseMusicBtn() {
      this.playMusic = true
      this.$root.$emit('clickedPauseMusicBtn')
    }
  },
  created() {
    this.$root.$on('clickedOpenMuisc', () => {
      setTimeout(() => this.musicButton = true, 1000);
    })
    this.$root.$on('clickedPlayingYtVideo', () => {
      this.playMusic = false
    })
    this.$root.$on('clickedPausedYtVideo', () => {
      this.playMusic = true
    })
  },
}
</script>

<style lang="scss" scoped>
.music-button {
  position: absolute;
  right: 50px;
  top: 100px;
  .v-btn--is-elevated.v-btn--fab.playMusic {
    box-shadow: 0px 0px 10px 2px rgb(198 11 11 / 63%), 0px 6px 10px 0px rgb(240 27 27 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  }
  .v-btn--is-elevated.v-btn--fab.pauseMusic {
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 0;
    &:before {
      content: '';
      background: linear-gradient(45deg, #A6160B, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
      position: absolute;
      top: -2px;
      left:-2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 5px);
      height: calc(100% + 5px);
      animation: glowing 20s linear infinite;
      opacity: 1;
      transition: opacity .3s ease-in-out;
    }
    &:active {
      color: #000
    }
    &:active:after {
      background: transparent;
    }
    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #111;
      left: 0;
      top: 0;
      border-radius: 50%;
    }
    @keyframes glowing {
      0% { background-position: 0 0; }
      50% { background-position: 400% 0; }
      100% { background-position: 0 0; }
    }
    // box-shadow: 0px 0px 20px 7px rgb(198 11 11 / 63%), 0px 6px 10px 0px rgb(240 27 27 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  }
  .theme--dark.v-btn.v-btn--has-bg {
    background-color: rgb(255 255 255 / 17%);
  }
}
</style>
