<template>
  <div class="auth">
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>

<style lang="scss">
  .auth {
    background-color: red;
  }
</style>
