<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="openMuisc"
          v-bind="attrs" 
          v-on="on"
          icon
          depressed
        >
          <v-icon 
          > mdi-playlist-music-outline 
          </v-icon>
        </v-btn>
      </template>
      <v-card
        dark
      >
        <v-toolbar
          dark
          color="rgb(166, 22, 11)"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Radio</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        
        <h2 class="text-2 text-center mt-7 mb-3 font-weight-light">Select your favorite radio</h2>
        <v-container class="justify-center">
          <v-row class="justify-center">
            <v-col
              class="d-flex justify-center"
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="select"
                :items="items"
                item-text="radioName"
                item-value="radioLink"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <!-- <v-divider></v-divider> -->
        <v-container>
          <v-row class="justify-center">
            <v-col
              class="d-flex justify-center"
              cols="12"
              md="6"
              sm="10"
            >
              <youtube
                :video-id="select.radioLink" 
                :player-vars="{autoplay: 0, rel: 0, controls: 1, modestbranding: 1, loop: 1, disablekb: 1, fs: 0, iv_load_policy: 3}"
                @ready="ready"
                @playing="playingYtVideo"
                @paused="pausedYtVideo"
              ></youtube>
            </v-col>
          </v-row>
        </v-container>

      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
  export default {
    name: 'AppMusic',
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        musicYt: true,
        select: { radioName: 'Lofi Hip Hop Radio', radioLink: '5qap5aO4i9A' },
        items: [
          { radioName: 'Lofi Hip Hop Radio', radioLink: '5qap5aO4i9A' },
          { radioName: 'The Good Life Radio', radioLink: '36YnV9STBqc' },
          { radioName: 'Chillout Lounge Radio', radioLink: '9UMxZofMNbA' },
          { radioName: 'Night Flow Music Radio', radioLink: 'K8kXcNqWN8g' },
          { radioName: 'Soft Music Radio', radioLink: 'Ek1-adoyE_0' },
          { radioName: 'Chillhop Music Radio', radioLink: '7NOSDKb0HlU' },
          { radioName: 'Relaxing Music 24/7 Radio', radioLink: '0WdNFesh2yg' },
          { radioName: 'Japanese Lofi 24/7 Radio', radioLink: '-9gEgshJUuY' },
          { radioName: 'Night Paris JAZZ Radio', radioLink: 'XnzxEGLaPxs' },
          { radioName: 'Jazz Piano Radio', radioLink: 'Dx5qFachd3A' },
          { radioName: 'Work & Jazz Radio', radioLink: 'dqQxMyHKp0w' },
          { radioName: 'Smooth Jazz Beats Radio', radioLink: 'bZZRdZbMnSk' },
          { radioName: 'Piano Music 24/7 Radio', radioLink: 'y7e-GC6oGhg' },
          { radioName: 'Deep Focus Radio', radioLink: '3rmWJAQ0Na4' },
          { radioName: 'Coffee Rain Radio', radioLink: '89XPBelvrZk' },
        ],
      }
    },
    methods: {
      ready (event) {
        this.player = event.target
      },
      openMuisc() {
        this.$root.$emit('clickedOpenMuisc')
      },
      playingYtVideo() {
        this.$root.$emit('clickedPlayingYtVideo')
      },
      pausedYtVideo() {
        this.$root.$emit('clickedPausedYtVideo')
      }
    },
    mounted() {
      this.$root.$on('clickedPlayMusicBtn', () => {
        this.player.playVideo()
      }),
      this.$root.$on('clickedPauseMusicBtn', () => {
        this.player.pauseVideo()
      })
    }
  }
</script>

<style lang="scss" scoped>
.v-card {
  background-color: #14171f;
}


</style>
