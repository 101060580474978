<template>
  <div class="main-layout">
    <app-the-nav></app-the-nav>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppTheNav from "../components/AppTheNav.vue";

export default {
  name: "MainLayout",
  components: {
    AppTheNav,
  },
};
</script>

