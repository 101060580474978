import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Task',
    meta: {
      layout: 'main'
    },
    component: () => import('../views/Task.vue')
  },
  {
    path: '/task/:task',
    name: 'Pomodorus',
    props: true,
    meta: {
      layout: 'main'
    },
    component: () => import('../views/Pomodorus.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      layout: 'main'
    },
    component: () => import('../views/About.vue')
  },
  {
    path: '*',
    name: '404',
    meta: {
      layout: 'main'
    },
    component: () => import('../views/404.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'auth'
    },
    component: () => import('../views/Login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
