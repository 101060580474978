import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueYouTubeEmbed from 'vue-youtube-embed'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(VueYouTubeEmbed)
Vue.filter('capitalize', function (value) {
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1).split('-').join(' ')
})
Vue.filter('toHowers', function convertHMS(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - (hours * 3600)) / 60);
  let seconds = sec - (hours * 3600) - (minutes * 60);
  if (hours === 0) {
    return minutes + 'm ' + seconds + 's';
  } else {
    return hours + 'h ' + minutes + 'm';
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')




