<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="450px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs" 
          v-on="on"
          icon
          depressed
        >
          <v-icon 
          > mdi-cog-outline
            <!-- mdi-playlist-music-outline -->
          </v-icon>
        </v-btn>
      </template>
      <v-card
        dark
      >
        <v-card-title>
          <span class="text-h5">Settings</span>
        </v-card-title>
        <v-divider
          class="pt-5"
        ></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12 pt-0 pb-0">
                <v-list-item-title class="white--text">Work Duration: {{focusTime}} min</v-list-item-title>
                <!-- <v-subheader class="white--text"></v-subheader> -->
                <v-slider
                  v-model="focusTime"
                  :max="60"
                  :min="5"
                  :thumb-size="26"
                  color="#a6160b"
                  thumb-color="#a6160b"
                  :thumb-label="true"
                  step="5"
                  ticks
                ></v-slider>
              </v-col>
              <v-col cols="12 pt-0 pb-0">
                <v-list-item-title class="white--text">Short Break: {{shortBreak}} min</v-list-item-title>
                <v-slider
                  v-model="shortBreak"
                  color="#a6160b"
                  thumb-color="#a6160b"
                  :thumb-label="true"
                  :thumb-size="26"
                  :max="60"
                  :min="5"
                  step="5"
                  ticks
                ></v-slider>
              </v-col>
              <v-col cols="12 pt-0 pb-0">
                <v-list-item-title class="white--text text-5">Long Break: {{longBreak}} min</v-list-item-title>
                <v-slider
                  v-model="longBreak"
                  color="#a6160b"
                  thumb-color="#a6160b"
                  :thumb-label="true"
                  :max="60"
                  :min="5"
                  :thumb-size="26"
                  step="5"
                  ticks
                ></v-slider>
              </v-col>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="notificationSwitch"
                    color="#a6160b"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Push Notifications</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="alarmSoundSwitch"
                    color="#a6160b"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Alarm Sound</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="autoStartSwitch"
                    color="#a6160b"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Autostart</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            text
            @click="saveSettings"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AppSettings",
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      focusTime: 25,
      shortBreak: 5,
      longBreak: 15,
      notificationSwitch: true,
      alarmSoundSwitch: true,
      autoStartSwitch: true,
    };
  },
  methods: {
    saveSettings() {
      this.dialog = false;

      this.$root.$emit('focusTimeSlider', this.focusTime)
      this.$root.$emit('shortBreakSlider', this.shortBreak)
      this.$root.$emit('longBreakSlider', this.longBreak)
      this.$root.$emit('switchNotification', this.notificationSwitch)
      this.$root.$emit('switchAlarmSound', this.alarmSoundSwitch)
      this.$root.$emit('switchAutoStart', this.autoStartSwitch)

      this.setItemLocalStorage()
     },
    setItemLocalStorage() {
      localStorage.setItem("FocusSession", this.focusTime);
      // let test = localStorage[id];
      localStorage.setItem("ShortBreak", this.shortBreak);
      localStorage.setItem("LongBreak", this.longBreak);
      localStorage.setItem("Notification", this.notificationSwitch);
      localStorage.setItem("AlarmSound", this.alarmSoundSwitch);
      localStorage.setItem("AutoStart", this.autoStartSwitch);
    }
  },
  mounted() {
    if (!localStorage.FocusSession) {
      this.setItemLocalStorage()
    } else {
      this.focusTime = JSON.parse(localStorage.FocusSession)
      this.shortBreak = JSON.parse(localStorage.ShortBreak)
      this.longBreak = JSON.parse(localStorage.LongBreak)
      this.notificationSwitch = JSON.parse(localStorage.Notification)
      this.alarmSoundSwitch = JSON.parse(localStorage.AlarmSound)
      this.autoStartSwitch = JSON.parse(localStorage.AutoStart)
    }
    this.$root.$on('clickSettings', (val) => {
      this.dialog = val
    })
  },
};
</script>


<style lang="scss" scoped>
.theme--dark.v-card {
  background-color: #14171f;
}
.v-sheet.v-card {
  border-radius: 20px;
}
</style>
